import React from "react";
import _ from "lodash";
import { cn } from "@/components/cn";

const defaultStyles = {
  fontSize: "text-[32px] sm:text-[68px]",
  fontWeight: "font-bold",
  lineHeight: "leading-[40px] sm:leading-[92px]",
};

function getStyles() {
  return _.values(defaultStyles);
}

interface DisplayProps {
  as: "h1" | "h2" | "h3";
  children: React.ReactNode;
  className?: string;
}

const Display: React.FC<DisplayProps> = ({ as: Tag, children, className = "" }) => {
  return <Tag className={cn(getStyles(), className)}>{children}</Tag>;
};

export default Display;
