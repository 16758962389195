"use client";

import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useTranslation } from "next-i18next";
import { Link, Button, Spacer } from "@nextui-org/react";
import Image from "next/image";
import moment from "moment";
import { useMediaQuery } from "react-responsive";

import { cn } from "@/components/cn";
import { getPaginatedPosts } from "@/sanity/client";
import Title from "@/components/typography/title";
import Body from "@/components/typography/body";
import Subtitle from "@/components/typography/subtitle";
import { urlForImage } from "@/sanity/image";
import Tag from "@/components/tag";

const NEWS_COUNT = 4;

const initialPosts = _.times(NEWS_COUNT, (index) => ({
  title: `story${index + 1}`,
}));

export default function Component({ className }: { className?: string }) {
  const { t, i18n } = useTranslation("home");
  const [posts, setPosts] = useState(initialPosts);
  const [isMounted, setIsMounted] = useState(false);

  const isLargeScreen = useMediaQuery({ query: "(min-width: 1280px)" });

  useEffect(() => {
    const fetchAndSetPostsAsync = async () => {
      try {
        const headPosts = await getPaginatedPosts({ limit: NEWS_COUNT });
        const newPosts = _.map(initialPosts, (post, index) => {
          const { title, excerpt, author, categories, publishedAt, slug, mainImage } = headPosts[index];
          return {
            ...post,
            title,
            author,
            excerpt,
            categories: _.map(categories, ({ title, color }) => ({ title, color })),
            publishedAt,
            slug,
            mainImage,
          };
        });
        setPosts(newPosts);
      } catch (err) {
        console.error(err);
      }
    };

    fetchAndSetPostsAsync();
    setIsMounted(true); // This sets the flag to true after the component mounts
  }, []);

  return (
    <section className={cn("container", className)}>
      <div className="flex justify-between">
        <Title as="h2" variant="large">
          {t("news.title")}
        </Title>
        <Button color="primary" variant="bordered" className="lg:px-6 py-4 lg:border-2 border-0" as={Link} href="/blog">
          <Body as="span" variant="large-stronger">
            {t("news.view-more")}
          </Body>
        </Button>
      </div>
      <Spacer y={8} />
      <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-6">
        <div className="col-span-2 sm:order-last">
          {posts[0]?.mainImage && (
            <Link href={`/blog/${posts[0]?.slug?.current}`} className="text-white aspect-[2/1] block">
              <Image
                className="rounded-xl rounded-b-none sm:rounded-b-xl border border-[#303030]"
                {...urlForImage(posts[0]?.mainImage)}
                alt={posts[0]?.mainImage.alt || "Thumbnail"}
              />
            </Link>
          )}
        </div>
        <div className="rounded-xl rounded-t-none sm:rounded-t-xl border-0 sm:border border-[#303030] bg-[#1C1C1C] py-4 lg:py-8 px-4 lg:px-8">
          <div className="flex flex-col sm:flex-col-reverse gap-6">
            <div>
              <Body as="p" variant="small">
                {moment(posts[0]?.publishedAt).locale(i18n.language).format("Do MMMM YYYY")}
              </Body>
              <Spacer y={4} />
              <Link href={`/blog/${posts[0]?.slug?.current}`} className="text-white w-full">
                <Title
                  as="h3"
                  variant={isMounted && isLargeScreen ? "large" : "small"}
                  className="line-clamp-2 xl:line-clamp-3"
                >
                  {posts[0]?.title}
                </Title>
              </Link>
            </div>
            <div>
              {_.map(posts[0]?.categories, ({ title, color }) => (
                <Tag text={title} key={title} colorName={color} />
              ))}
            </div>
          </div>
          <Spacer y={6} />
          <div>
            <Button
              color="primary"
              variant="bordered"
              className="w-full sm:w-auto px-6 py-4 border-2"
              as={Link}
              href={`/blog/${posts[0]?.slug?.current}`}
            >
              <Body as="span" variant="large-stronger">
                {t("news.read-more")}
              </Body>
            </Button>
          </div>
        </div>
      </div>
      <Spacer y={8} />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {_.map(_.slice(posts, 1), ({ title, author, publishedAt, mainImage, slug }, index) => (
          <div
            key={`item.description-${index}`}
            className="w-full flex flex-col items-start bg-[#0B0B0B] rounded-xl border border-[#303030] overflow-hidden"
          >
            <Link href={`/blog/${slug?.current}`} className="text-white">
              {mainImage && <Image {...urlForImage(mainImage)} alt={mainImage.alt || "Thumbnail"} />}
            </Link>
            <div className="px-4 py-5">
              <Link href={`/blog/${slug?.current}`} className="text-white">
                <Subtitle as="p" variant="medium" className="line-clamp-2">
                  {title}
                </Subtitle>
              </Link>
              <Spacer y={2} />
              {author && (
                <Link href={`/blog/author/${author.slug.current}`} className="text-[#802CFD]">
                  <Body as="p" variant="small-strong">
                    {author.name}
                  </Body>
                </Link>
              )}
              <Spacer y={2} />
              <Body as="p" variant="small" className="text-white opacity-60">
                {moment(publishedAt).locale(i18n.language).format("Do MMMM YYYY")}
              </Body>
            </div>
          </div>
        ))}
      </div>
      <Spacer y={8} />
      <div className="flex justify-center">
        <Button
          color="primary"
          variant="bordered"
          className="w-full sm:w-auto px-6 py-4 border-2"
          as={Link}
          href="/blog"
        >
          <Body as="span" variant="large-stronger">
            {t("news.view-more")}
          </Body>
        </Button>
      </div>
    </section>
  );
}
