import _ from "lodash";
import React from "react";
import { Accordion, AccordionItem, Spacer } from "@nextui-org/react";
import Image from "next/image";
import Title from "../../components/typography/title";
import Subtitle from "../../components/typography/subtitle";
interface TranslatedItem {
  title: string;
  content: string;
}
interface FAQProps {
  t: (key: string, options?: { returnObjects: boolean }) => any;
  className?: string;
}

function FAQ({ t, className }: FAQProps) {
  const translated = t("items") as TranslatedItem[];

  return (
    <section className={className}>
      <div className="relative">
        <Image
          className="absolute hidden lg:block w-full top-[180px]"
          src="/image/common/faq/bg-wave1.svg"
          width={1440}
          height={720}
          alt="bg-wave1"
        />
        <Image
          className="absolute hidden lg:block w-full top-[120px]"
          src="/image/common/faq/bg-wave2.svg"
          width={1440}
          height={651}
          alt="bg-wave2"
        />
        <Image
          className="absolute hidden lg:block w-full top-[170px]"
          src="/image/common/faq/bg-wave3.svg"
          width={1440}
          height={805}
          alt="bg-wave3"
        />
      </div>
      <div className="container sm:px-6 lg:px-8">
        <div className="text-center">
          <Subtitle as="h3" variant="medium-strong" className="text-primary">
            {t("subtitle")}
          </Subtitle>
          <Title as="h2" variant="small" className="text-white">
            {t("title")}
          </Title>
        </div>
        <Spacer y={10} />
        <div className="flex flex justify-center">
          <div className="w-full xl:w-4/5">
            <Accordion
              fullWidth
              keepContentMounted
              className="gap-3"
              itemClasses={{
                base: "px-6 bg-default-100 shadow-none hover:bg-default-200/50 z-10",
                title: "font-medium",
                trigger: "py-6",
                content: "pt-0 pb-6 text-base text-default-500",
              }}
              items={translated}
              selectionMode="multiple"
              variant="splitted"
            >
              {_.map(translated, (item) => (
                <AccordionItem
                  key={item.title}
                  indicator={({ isOpen }) =>
                    isOpen ? (
                      <Image
                        src="/image/common/faq/minus.svg"
                        width={28}
                        height={28}
                        alt={"Accordian close indicator"}
                      />
                    ) : (
                      <Image src="/image/common/faq/plus.svg" width={28} height={28} alt={"Accordian open indicator"} />
                    )
                  }
                  title={item.title}
                >
                  {item.content}
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
