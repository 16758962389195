import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { cn } from "@/components/cn";

const cardStyle = {
  borderRadius: 14,
  background: "var(--Glass-Grey-1, rgba(161, 161, 170, 0.16))",
};

const Component = ({ children, className }) => {
  return (<div className={className} style={cardStyle}>
						{children}
					</div>);
};

Component.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Component.defaultProps = {
  className: "",
};

export default Component;
