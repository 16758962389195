"use client";

import React, { useEffect, useState } from "react";
import _ from "lodash";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import ReactPlayer from "react-player";

import Title from "@/components/typography/title";
import Body from "@/components/typography/body";
import config from "@/config";

export default function Component({ className }: { className?: string }) {
  const { t } = useTranslation("home");
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <section className={className}>
      {/* Background texture */}
      <div className="relative">
        <Image
          className="hidden sm:block absolute top-[140px] w-full"
          src="/image/home/mainvideo/bg-wave.svg"
          alt="bg-waves"
          width={1440}
          height={1112}
        />
        <Image
          className="hidden sm:block absolute -top-[10px] w-full"
          src="/image/home/mainvideo/bg-wave2.svg"
          alt="bg-waves"
          width={1440}
          height={547}
        />
      </div>
      {/* Section content */}
      <div className="container my-auto flex flex-col relative">
        {/* Stars in background */}
        <Image
          className="hidden sm:block absolute left-1/2 -translate-x-1/2 -bottom-[80px] w-[902px]"
          src="/image/home/mainvideo/stars.svg"
          alt="bg-stars"
          width={902}
          height={219}
        />
        <Image
          className="hidden sm:block absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
          src="/image/home/mainvideo/bg-glow.svg"
          alt="bg-glow"
          width={1439}
          height={1020}
        />
        {/* Content */}
        <div className="relative text-center z-10">
          <Title as="h2" variant="large">
            {t("main-video.title")}
          </Title>
          <div className="flex justify-center mt-4 lg:mt-5">
            <Body as="p" variant="large" className="w-full lg:w-3/4 xl:w-1/2 text-white/60">
              {t("main-video.description")}
            </Body>
          </div>
        </div>
        <div className="flex justify-center mt-9">
          <div className="w-full relative lg:w-3/4 xl:w-2/3 2xl:w-2/3 aspect-video">
            {/* Boxs in background */}
            <Image
              className="hidden sm:block absolute -left-[180px] top-0"
              src="/image/home/mainvideo/box1.svg"
              alt="bg-box1"
              width={76}
              height={61}
            />
            <Image
              className="hidden sm:block absolute -left-[90px] top-[120px]"
              src="/image/home/mainvideo/box1.svg"
              alt="bg-box2"
              width={30}
              height={28}
            />
            <Image
              className="hidden sm:block absolute -right-[90px] bottom-[90px]"
              src="/image/home/mainvideo/box3.svg"
              alt="bg-box3"
              width={38}
              height={38}
            />
            <Image
              className="hidden sm:block absolute -right-[220px] bottom-0"
              src="/image/home/mainvideo/box4.svg"
              alt="bg-box4"
              width={75}
              height={80}
            />
            {/* Video */}
            {isMounted && <ReactPlayer url={config.mainVideo.url} width="100%" height="100%" />}
          </div>
        </div>
      </div>
    </section>
  );
}
