import React from "react";
import _ from "lodash";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { Spacer } from "@nextui-org/react";

import Title from "@/components/typography/title";
import Body from "@/components/typography/body";
import { cn } from "@/components/cn";

const icons = [
  "/image/home/corevalues/1.svg",
  "/image/home/corevalues/2.svg",
  "/image/home/corevalues/3.svg",
  "/image/home/corevalues/4.svg",
];

interface CoreValuesProps {
  className?: string;
}

function CoreValues({ className }: CoreValuesProps) {
  const { t } = useTranslation("home");
  const data = t("corevalues.items", { returnObjects: true }) as [
    { title: string; description: string },
  ];
  const corevalues = _.map(data, ({ title, description }, index) => ({
    title,
    description,
    icon: icons[index],
  }));
  return (
    <section className={cn(className, "container")}>
      <Title as="h2" variant="large" className="text-center relative">
        {t("corevalues.title")}
      </Title>
      <Spacer y={10} />
      <div className="relative flex flex-wrap gap-y-6 z-10 pb-12">
        {_.map(corevalues, ({ title, description, icon }) => (
          <div className="lg:w-1/4 w-full px-3" key={title}>
            <div
              className="py-8 px-6 h-full"
              key={title}
              style={{
                borderRadius: "var(--XX-Large, 12px)",
                background: "var(--Glass-Grey-1, rgba(161, 161, 170, 0.16))",
              }}
            >
              <Image src={icon} width={64} height={64} alt={title} />
              <Spacer y={5} />
              <Title as="h2" variant="small" className="text-white leading-8">
                {title}
              </Title>
              <Spacer y={3} />
              <Body as="p" variant="large" className="text-zinc-300 opacity-60">
                {description}
              </Body>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default CoreValues;
