"use client";

import React from "react";
import _ from "lodash";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import { Spacer, Tabs, Tab } from "@nextui-org/react";

import Title from "@/components/typography/title";

const projects = {
  talisman: { name: "Talisman", img: "/image/home/partners/talisman.png", url: "https://www.talisman.xyz/" },
  subwallet: { name: "SubWallet", img: "/image/home/partners/subwallet.png", url: "https://www.subwallet.app/" },
  kusama: { name: "Kusama", img: "/image/home/partners/kusama.png", url: "https://kusama.network/" },
  moonriver: {
    name: "Moonriver",
    img: "/image/home/partners/moonriver.png",
    url: "https://moonbeam.network/networks/moonriver/",
  },
  shiden: { name: "Shiden", img: "/image/home/partners/shiden.png", url: "https://shiden.astar.network/" },
  polkassembly: {
    name: "Polkassembly",
    img: "/image/home/partners/polkassembly.png",
    url: "https://polkassembly.io/",
  },
  subsquare: { name: "Subsquare", img: "/image/home/partners/subsquare.png", url: "https://www.subsquare.io/" },
  mangata: { name: "Mangata", img: "/image/home/partners/mangata.png", url: "https://www.mangata.finance/" },
  onfinality: {
    name: "OnFinality",
    img: "/image/home/partners/onfinality.png",
    url: "https://onfinality.io/",
  },
  subscan: { name: "Subscan", img: "/image/home/partners/subscan.png", url: "https://www.subscan.io/" },
  subquery: { name: "SubQuery", img: "/image/home/partners/subquery.png", url: "https://subquery.network/" },
  subsocial: { name: "SubSocial", img: "/image/home/partners/subsocial.png", url: "https://subsocial.network/" },
  blockdaemon: {
    name: "Blockdaemon",
    img: "/image/home/partners/blockdaemon.png",
    url: "https://www.blockdaemon.com/",
  },
  acala: { name: "Acala", img: "/image/home/partners/acala.png", url: "https://acala.network/" },
  oneblock: { name: "OneBlock", img: "/image/home/partners/oneblock.png", url: "https://medium.com/@OneBlockplus" },
  polkaworld: { name: "Polkaworld", img: "/image/home/partners/polkaworld.png", url: "https://polkaworld.medium.com/" },
  web3go: { name: "Web3Go", img: "/image/home/partners/web3go.png", url: "https://web3go.xyz/" },
  cumulon: { name: "Cumulon", img: "/image/home/partners/cumulon.png", url: "https://cumulon.cloud/" },
  trustedNode: {
    name: "Trusted Node",
    img: "/image/home/partners/trustednode.png",
    url: "https://trustednode.io/",
  },
  cmssa: { name: "CMSSA", img: "/image/home/partners/cmssa.png", url: "https://www.cmssa.org/" },
  berkeley: { name: "Berkeley", img: "/image/home/partners/xcelerator.png", url: "https://xcelerator.berkeley.edu/" },
  bifrost: { name: "Bifrost", img: "/image/home/partners/bifrost.png", url: "https://bifrost.finance/" },
};

const tagList = [
  {
    items: _.values(projects),
  },
  {
    items: [projects.web3go],
  },
  {
    items: [projects.kusama, projects.moonriver, projects.shiden, projects.acala, projects.mangata, projects.bifrost],
  },
  {
    items: [projects.polkassembly, projects.subsquare],
  },
  {
    items: [projects.talisman, projects.subwallet],
  },
  {
    items: [projects.subscan, projects.subquery, projects.onfinality, projects.blockdaemon, projects.trustedNode],
  },
  {
    items: [projects.talisman, projects.subsocial, projects.oneblock, projects.polkaworld, projects.cmssa],
  },
];

export default function Component({ className }: { className?: string }) {
  const { t } = useTranslation("home");
  const tagTitles = t("ecosystem.tags", { returnObjects: true });
  const tags = _.map(tagList, ({ items }, index) => ({ title: tagTitles[index], items }));
  return (
    <section className={className}>
      {/* Background texture */}
      <div className="relative">
        <Image
          className="hidden sm:block absolute top-[-110px] w-full"
          src="/image/home/ecosystem/bg-waves.svg"
          alt="bg-waves"
          width={1440}
          height={1302}
        />
      </div>
      {/* Section content */}
      <div className="container my-auto flex flex-col relative bg-none sm:bg-[url('/image/home/ecosystem/bg-grid-lines.svg')] bg-no-repeat">
        <div className="relative text-center">
          {/* Title highlight */}
          <div className="hidden sm:block w-[300px] h-[52px] absolute top-2/4 left-2/4 blur-[110.5px] bg-[#3D1AFF] -translate-x-1/2 -translate-y-1/2 rounded-[100px] shrink-0" />
          <Title as="h2" variant="large">
            {t("ecosystem.title")}
          </Title>
        </div>
        <Spacer y={7} />
        <Tabs
          variant="light"
          size="lg"
          aria-label=""
          classNames={{ panel: "pt-9", tab: "sm:px-8", base: "justify-center" }}
        >
          {_.map(tags, (tag) => (
            <Tab key={tag.title} title={tag.title}>
              <div className="flex flex-wrap gap-x-4 sm:gap-x-16 gap-y-12 justify-center">
                {_.map(tag.items, (item, itemKey) => (
                  <div key={itemKey} className="flex flex-col items-center" style={{ width: 100 }}>
                    <div style={{ width: 60, height: 60 }} className="flex items-center">
                      <Image src={item.img} alt={item.name} width={60} height={60} />
                    </div>
                    <Spacer y={2} />
                    <p>{item.name}</p>
                  </div>
                ))}
              </div>
            </Tab>
          ))}
        </Tabs>
      </div>
    </section>
  );
}
