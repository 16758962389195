import React, { useState, useEffect } from "react";

import { Link, Button, Spacer } from "@nextui-org/react";
import { useTranslation, Trans } from "next-i18next";
import Image from "next/image";

import Lottie from "@/components/lottie";
import Display from "@/components/typography/display";
import Body from "@/components/typography/body";
import BgShape1 from "./rectangle-177.svg";
import BgShape2 from "./rectangle-178.svg";
// import animationData from "/lottie/hero.json";
import config from "../../../config";
import { cn } from "@/components/cn";

const { appsURL } = config;

function Intro({ className }) {
  const { t } = useTranslation("home");

  return (
    <section className={cn("relative", className)}>
      {/* Background Shape; The height of top navbar is 60px, so the top-[-60px] will make the shape sticky on top */}
      <div className="hidden sm:block absolute w-[1063px] h-[631px] top-[-120px] left-[-140px] z-10 pointer-events-none">
        <BgShape1 width={1063} height={631} />
      </div>
      <div className="hidden sm:block absolute w-[1066px] h-[871px] top-[-120px] left-[-140px] z-10 pointer-events-none">
        <BgShape2 width={1066} height={871} />
      </div>

      {/* Page Content */}
      <div className="relative px-4 pt-12 mx-auto max-w-7xl sm:pt-[100px] z-20">
        <div className="w-full mx-auto text-center md:w-11/12">
          <Display as="h1">
            <Trans
              i18nKey="intro.supercharging-web3"
              t={t}
              components={[
                <span
                  key="single-key"
                  className="bg-gradient-to-r from-violet-600 to-pink-600 bg-clip-text text-transparent"
                >
                  Web3
                </span>,
              ]}
            />
          </Display>
          <Spacer y={2} />
          {/* TODO: Fixed the width of the h2 using w-4/5 */}
          <Body as="h2" variant="large" className={"md:px-[15%] xl:px-[30%]"}>
            {t("intro.ava-intro")}
          </Body>
          <div className="flex mt-6 md:ml-6 justify-center gap-6">
            <Button
              href={appsURL}
              isExternal
              as={Link}
              radius="sm"
              color="primary"
              variant="solid"
              className="font-bold"
            >
              {t("intro.try-testnet")}
            </Button>
            <Button href="/docs" as={Link} radius="sm" className="bg-transparent font-bold">
              {t("intro.start-building")}
            </Button>
          </div>
        </div>
      </div>
      {/* Lottie Animation in the Background */}
      <div className="flex justify-center pointer-events-none">
        <div className="flex relative w-full justify-center items-center overflow-hidden h-[300px] lg:h-[400px] 2xl:h-[600px]">
          <div className="absolute top-[45%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[900px] lg:w-[1600px] 2xl:w-[2400px] ">
            <Lottie animationData="/lottie/hero.json" />
          </div>
          {/* Gradient Overlay */}
          <div className="absolute inset-x-0 top-0 h-[10%] lg:h-[25%] 2xl:h-[30%] bg-gradient-to-b from-[#000]/70" />
          <div className="absolute inset-x-0 bottom-0 h-[10%] lg:h-[25%] 2xl:h-[30%] bg-gradient-to-t from-[#000]/70" />
          <div className="absolute inset-y-0 left-0 w-[10%] lg:w-[25%] 2xl:w-[30%] bg-gradient-to-r from-[#000]/70" />
          <div className="absolute inset-y-0 right-0 w-[10%] lg:w-[25%] 2xl:w-[30%] bg-gradient-to-l from-[#000]/70" />
        </div>
      </div>
      {/* Background texture */}
      <div className="hidden sm:block absolute w-[1288px] h-[571px] top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 overflow-x-hidden">
        <Image src="/image/home/intro/stars_hero_section.svg" width={1288} height={571} alt="stars" />
      </div>
    </section>
  );
}

export default Intro;
