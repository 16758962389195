"use client";
import { useEffect, useRef, CSSProperties } from "react";
import Lottie from "lottie-web";
import type { LottiePlayer } from "lottie-web";

interface LottiePlayerProps {
  animationData: string;
  style?: CSSProperties;
  loop?: boolean;
}

const LottiePlayer: React.FC<LottiePlayerProps> = ({ animationData, style = {}, loop = true }) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      const animation = Lottie.loadAnimation({
        container: ref.current,
        renderer: "svg",
        loop,
        autoplay: true,
        path: animationData,
      });

      return () => animation.destroy();
    }
  }, [animationData, loop]);

  return <div ref={ref} style={style} />;
};

export default LottiePlayer;
